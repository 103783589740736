<template>
  <div class="Per_manage">
    <div class="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条数据吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
        <p class="search">
        <el-input
          type="text"
          size="mini"
          clearable
          v-model="searchForm.name"
          placeholder=" 请输入用户名/账号"
        ></el-input>
        <button class="btn1" @click="currentPage2=1;getData()" v-if="permissoin.includes('query')">
          <i class="el-icon-search"></i>
          查询
        </button>
         <button class="btn2" @click="modal=1" 
      v-if="permissoin.includes('add')"
         >
          <i class="el-icon-plus"></i>
          新增
        </button>
      </p>
      <div class="table srcollstyle">
           <el-table :data="tableData" style="width:98%" border max-height='780px'>
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="name" label="角色名称" >
            </el-table-column>
            <el-table-column prop="roleCode" label="角色编码" >
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-edit" 
                      v-if="permissoin.includes('edit')"
                      :command="{ num: 2, data: scope.row }"
                      >编辑</el-dropdown-item>
                      
                    <el-dropdown-item
                    icon="el-icon-delete" 
                    v-if="permissoin.includes('del')"
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item>

                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage2"
          @size-change="handleSizeChange"
          prev-text="上一页"
          next-text="下一页"
          :page-size="pageSize"
          :page-sizes="[5,10,15,20]"
          layout="total,sizes, prev, pager, next, jumper "
          :total="total"
        >
        </el-pagination>
    </div>
    </div>
    <div class="modal" v-if="modal==1">
        <div class="inner">
          <p class="title">
          <span>新增角色</span>
          <span @click="modal = 0" class="pointer">
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
            <el-form :model="ruleForm" :rules="rules" status-icon ref="ruleForm" label-width="80px" label-position="left">
              <el-form-item label="角色名:" prop="name">
                <el-input v-model="ruleForm.name" size='mini'></el-input>
              </el-form-item>
              <el-form-item label="编码:" prop="roleCode">
                <el-input v-model="ruleForm.roleCode" size='mini'></el-input>
              </el-form-item>            
              </el-form>
              
        </div>
        <div class="bottom">
            
            <button class="btn_red" @click="onsubmit">保存</button>
            <button class="btn_gray" @click="ruleForm={};modal=0">取消</button>
        </div>
          </div>
    </div>
     <div class="modal" v-show="modal==2">
        <div class="inner">
          <p class="title">
          <span>选择菜单</span>
          <span @click="modal = 0" class="pointer">
            <i class="el-icon-close"></i>
          </span>
        </p>
        <p class="name">
          <span>角色名称</span> 
          <el-input
          type="text"
          size="mini"
          clearable
          v-model="rolename"
          placeholder=" 请输入角色名"
        ></el-input>
        </p>
        <div class="modal_center1 scrollbar_width0">
          <span class="qx">角色权限</span> 
            <el-tree
              ref="menuTree"
              :props="props"
              node-key="id"
              show-checkbox
              :check-strictly='true'
              :data="treedataNew"
              @check="clickDeal"
            >
            </el-tree>
              
        </div>
        <div class="bottom">
            
            <button class="btn_red" @click="onsubmit1">保存</button>
            <button class="btn_gray" @click="modal=0">取消</button>
        </div>
          </div>
    </div>
  </div>
</template>

<script>
import { roleGetList,roleSave,getMenuAll,queryMenuByRoleId,
saveRoleAndMenu,roleDel
} from '@/api/apis.js'
export default {
  data() {
    return {
      option1: [],
      form: {},
      treeShow:0,
      info: 1,
      currentPage2:1,
      dialogVisible:false,
      pageSize:10,
      hide:1,
      keysArr:[],
      arr:[],
      modal:0,
      total:0,
      permissoin:[],
      searchForm:{},
      rolename:'',
      ruleForm:{
        pwd:123456,
        date1: '',
          date2: '',
      },
       options: [],
        props: {
        label: "name",
        children: "children",
      },
      treedataNew:[],
      tableData: [],
        rules: {
          name: [
            { required: true, message: '请输入角色名', trigger: 'blur' },
          ],
          roleCode: [
            { required: true, message: '请输入角色编码', trigger: 'blur' },
          ],
          }
    };
  },
  methods: {
    getData(){
      let data={
        name:this.searchForm.name,
        pageSize:this.pageSize,
        pageNum:this.currentPage2,
      }
      roleGetList(data).then(res=>{
        if(res.code==200){
        this.tableData=res.data.list
        this.total=res.data.total-0

      }else{
        this.tableData=[]     
      }
      })},
       getData1(){
      getMenuAll().then(res=>{
          if(res.code==200){
        this.treedataNew=res.data
      }else{
        this.treedataNew=[]     
      }
      })
    },
      onsubmit(){
        let data={
        name:this.ruleForm.name,
        roleCode:this.ruleForm.roleCode,
      }
       this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            roleSave(data).then(res=>{
        if(res.code==200){
        this.$message({
          type:'success',
          message:'新增成功'
        })
        this.getData()
        this.modal=0
      }else{
        this.$message.error(res.data.msg)    
      }
      })
          } else {
            return false;
          }
        });
      
      },
      deletePro(){
      let data ={roleId:this.form.id}
        roleDel(data).then(res=>{
          if(res.code==200){
            this.$message({
              type:'success',
              message:'删除成功'
            })
            this.dialogVisible=false
            this.getData()
          }else{
           this.$message.error(res.data.msg)
          }
        })
    },
    handleCurrentChange(v){this.currentPage2=v
      this.getData()},
    handleCommand(d){
      if(d.num==2){     
        this.rolename=d.data.name
        this.$refs.menuTree.setCheckedKeys([])
        this.form.id=d.data.id
        let data={roleId:d.data.id}
        queryMenuByRoleId(data).then(res=>{
          if(res.code==200){
         this.$refs.menuTree.setCheckedKeys(res.data);    
         this.modal=2  
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }else if(d.num==3){
        this.form.id=d.data.id
        this.dialogVisible=true
      }
    },
    clickDeal (currentObj, treeStatus) {
  // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
  let selected = treeStatus.checkedKeys.indexOf(currentObj.id) // -1未选中
  // 选中
  if (selected !== -1) {
    // 子节点只要被选中父节点就被选中
    this.selectedParent(currentObj)
    // 统一处理子节点为相同的勾选状态
    this.uniteChildSame(currentObj, true)
  } else {
    // 未选中 处理子节点全部未选中
    if (currentObj.children.length !== 0) {
      this.uniteChildSame(currentObj, false)
    }
  }
},
// 统一处理子节点为相同的勾选状态
uniteChildSame (treeList, isSelected) {
  this.$refs.menuTree.setChecked(treeList.id, isSelected)
  if(treeList.children){
      for (let i = 0; i < treeList.children.length; i++) {
              this.uniteChildSame(treeList.children[i], isSelected)            
        }
  }
  
},
// 统一处理父节点为选中
selectedParent (currentObj) {
  let currentNode = this.$refs.menuTree.getNode(currentObj)
  if (currentNode.parent.key !== undefined) {
    this.$refs.menuTree.setChecked(currentNode.parent, true)
    this.selectedParent(currentNode.parent)
  }
},
    onsubmit1(){
      let menuArr = this.$refs.menuTree.getCheckedKeys().concat(this.$refs.menuTree.getHalfCheckedKeys());
      let data={roleId:this.form.id,grantMenuIdList:menuArr.join(','),roleName:this.rolename}
      saveRoleAndMenu(data).then(res=>{
          if(res.code==200){
        this.$message({
          type:'success',
          message:'操作成功'
        })
        this.getData()
        this.modal=0
          }else{
            this.$message.error(res.data.msg) 
          }
      })
    },
    handleSizeChange(v){
      this.pageSize=v
      this.getData()
    },
  },
  mounted() {
    //获取权限
    this.permissoin=[]
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){  
            this.permissoin.push(item.permission)        
      }
    })
    this.getData()
    this.getData1()
  },
};
</script>

<style lang="less" >
.Per_manage {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main{
    flex: 1;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    box-sizing: border-box;
    .search{
      margin: 22px 0;
      .el-input{
        width: 268px;
      }
    }
    .table{
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide{
      position: absolute;
      top:48%;
      left: 0px;
      cursor: pointer;
    }
  }
   .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      // width: 456px;
      // height: 316px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px  #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .name{
        padding-left: 20px;
        margin-top: 20px;
        &>span:nth-child(1){
          margin-right: 16px;
          color: #606266;
        }
        .el-input{
          width: 200px;
          
        }
      }
      .modal_center{
        padding: 0 30px;
        padding-top: 10px;
          .text_item{
            margin-bottom: 12px;
            &>span:nth-child(1){
              color: #F56C6C;
              margin-right: 2px;
            }
            &>span:nth-child(2){
              display: inline-block;
              width: 60px;
            }
          }
          .el-form{
            .el-form-item__label{
              font-size: 12px;
              color: #222328;
            }
            .el-form-item{
              margin-bottom: 12px;
            }
            .el-input{
              width: 270px;
            }
            .el-form-item__error{
              padding-top: 0px;
            }
            // .el-form-item__content{
            //   margin-left: 0px !important;
            // }
            .two_item{
              display: flex;
              .ti_label{
                display: inline-block;
                width: 80px;
              }
              .ti_label1{
                display: inline-block;
                width: 55px;
                margin-left: 14px;
                margin-right: 2px;
              }
              .el-input{
              width: 80px;
            }
            .little{
              font-size: 8px;
            }
            }
          }
          .input_item{
              margin-bottom: 24px;
              
              &>span:nth-child(1){
                color: #F56C6C;
                margin-right: 2px;
              }
              &>span:nth-child(2){
                display: inline-block;
                margin-right: 8px;
              }
              .el-input{
                width: 270px;
              }
            }
          .mc_right{
            width: 400px;
            .mcr_title{
              margin-top: 18px;
              margin-bottom: 22px;
              i{
                font-size: 14px;
                color:#f37d00 ;
              }
            }
            .input_item{
              margin-bottom: 24px;
              
              &>span:nth-child(1){
                color: #F56C6C;
                margin-right: 2px;
              }
              &>span:nth-child(2){
                width: 60px;
                display: inline-block;
                margin-right: 8px;
              }
              .el-input{
                width: 270px;
              }
            }
            .jwd{
              display: flex;
              justify-content: space-between;
              font-size: 8px;
              line-height: 18px;
              margin-top: -24px;
              width: 350px;
              box-sizing: border-box;
              padding: 0 6px;
              &>span:nth-child(2){
                color: #0090ff;
              }
            }
            .map{
              // background-color: rgb(116, 35, 35);
              width: 360px;
              height: 310px;
            }
          }
        
      }
      .modal_center1{
        padding-left: 70px;
        padding-top: 10px;
        min-width: 260px;
        min-height: 400px;
        max-height: 600px;
        position: relative;
        .qx{
          position: absolute;
          top:16px;
          left: 20px;
          color: #606266;
        }
      }
      .bottom{
        height: 54px;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        &>button:nth-child(1){
          margin-right: 20px;
        }
      }
    }
   }
}
</style>